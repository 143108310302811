
































































































































import { Component, Vue } from "vue-property-decorator";
import { getchannelCooperationImg } from "@/api/home";
@Component({
  components: {},
})
export default class Cooperation extends Vue {
  PageImgList = [];
  Cooperationlist = [];
  activeIndex = '0';
  created() {
    getchannelCooperationImg().then((res) => {
      this.PageImgList = res.data.channelCooperationImg.split(",");
      this.Cooperationlist = res.data.advantageClassificationVoList;
    });
  }
  handleSelect(key: any) {
    this.activeIndex =String(key);
  }
  onChatClick() {
      (window as any).qimoChatClick();
    }
}
